<template>
  <div class="block">
    <el-carousel height="325px">
      <el-carousel-item
        v-for="(item, index) in bannerList"
        :key="index"
        :style="{
          backgroundImage: 'url(' + picHead + item.picUrl + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }"
        @click.native="toList(item.navtypeId)"
      >
        <div class="item">
          {{ item.picTitle }}
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: ['bannerList'],
  data() {
    return {};
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  methods: {
    toList(navtypeId) {
      this.$emit('toList', navtypeId)
    },
  },
};
</script>
<style lang="scss" scoped>
.el-carousel__item {
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  .item {
    line-height: 29px;
    color: rgba(255, 255, 255, 100);
    font-size: 20px;
    font-family: SourceHanSansSC-regula;
    width: auto;
    max-width: calc(100% - 40px);
    padding: 0 5px;
    box-sizing: border-box;
    position: absolute;
    bottom: 20px;
  }
}
</style>