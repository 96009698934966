<template>
  <div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <div class="home-con" v-if="!articlesParams.navTypeId">
        <div class="carousel">
          <Banner :bannerList="bannerList" />
        </div>
        <div class="top">
          <div
            class="box-item"
            v-for="(item, index) in data"
            :key="index"
            v-if="index < 2"
            :style="{
              backgroundImage: 'url(' + picHead + item.poster + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }"
            @click="toDetail(item)"
          >
            <div class="item-info">
              <div class="item-title">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="home-content"
        v-for="(item, index) in pullDownArticlesData"
        :key="index"
      >
        <div
          class="con-item"
          v-for="cItem in item"
          :key="cItem.id"
          @click="toDetail(cItem)"
        >
          <div
            :style="{
              backgroundImage: 'url(' + picHead + cItem.poster + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: '145px',
              backgroundPosition: 'center center'
            }"
            class="bg-img"
          >
            <!-- mediaType 文章的类型，0为默认，1表示为视频文章 -->
            <svg
              v-if="cItem.mediaType == 1"
              t="1610436444420"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1136"
              width="200"
              height="200"
            >
              <path
                d="M526.0288 16.1792a485.0688 485.0688 0 1 1 0 970.0864 485.0688 485.0688 0 0 1 0-970.0864zM467.456 337.8176a40.96 40.96 0 0 0-40.96 40.96v244.8896a40.96 40.96 0 0 0 63.6928 34.0992l183.7056-122.4704a40.96 40.96 0 0 0 0-68.1472L490.1888 344.6784a40.96 40.96 0 0 0-22.7328-6.8608z"
                p-id="1137"
              ></path>
            </svg>
          </div>
          <div class="con-text">
            <div class="texts">
              {{ cItem.title }}
            </div>
          </div>
          <div class="tips-con">
            <div class="date">
              <span>{{ cItem.createDate | dateFormate }}</span>
            </div>
            <div class="tips">
              <span v-if="cItem.commentNum">{{ cItem.commentNum }}评论</span>
              <span v-if="cItem.thumbsNum">{{ cItem.thumbsNum }}赞</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-for="item in data" :key="item.index">{{ item.id }}</div> -->
    </div>
    <div id="loadMore" v-show="droping">加载中</div>
    <div id="loadMore" v-show="!droping && nodata">没有更多的数据</div>
  </div>
</template>

<script>
import Banner from '../component/Banner';
export default {
  inject: ['reload'],
  components: { Banner },
  data() {
    return {
      pullDownArticlesData: [],
      data: [],
      busy: false,
      articlesParams: {
        pageNo: 1,
        pageSize: 20,
        queryType: 1, // 1,分页查询;2,list查询;3,首页推荐查询;
        navTypeId: '', // 搜索条件(文章分类)
      },
      nodata: false,
      droping: false, // 轮播图====开始
      bannerList: [],
      // 轮播图====结束
    };
  },

  watch: {
    $route(to, from) {
      console.log('路由变化');
      this.reload();
    },
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  created() {
    this.articlesParams.navTypeId = this.$route.query.navTypeId;
    this.getInit()
  },
  methods: {
    getInit() {
      // 获取banner图 linkType: 2
      this.$request(this.$api.getPictures, { type: 0 }).then((res) => {
        if (res.success) {
          this.bannerList = res.datas;
        }
      });
    },
    toDetail(val) {
      let routeData = this.$router.resolve({
        name: 'Detail',
        params: {
          id: val.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    loadMore() {
      this.busy = true;
      this.droping = true; //加载中
      setTimeout(() => {
        this.$request(this.$api.getArticles, this.articlesParams)
          .then((res) => {
            this.droping = false; //加载中
            if (res.success) {
              if (this.articlesParams.pageNo > res.datas.totalPage)
                return false;
              this.data = this.data.concat(res.datas.datas);
              if (this.data.length == res.datas.totalCount) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
              this.articlesParams.pageNo++;
              this.busy = false;

              this.pullDownArticlesData = this.data.reduce(
                (pre, next, idx) => {
                  // reduce 用来便利数组，具体语法就 rtfm 吧
                  const inner = pre[~~(idx / 4)]; // ~~用来取整，inner 是内层数组
                  if (inner !== undefined) {
                    // 判断有没有内层数组
                    inner.push(next); // 如果有就把遍历的值 next push 到内层数组里
                  } else {
                    pre.push([next]); // 没有就新建一个包含 next 的数组，作为内层数组
                  }
                  return pre;
                },
                [[]]
              );
              console.log('getServer', this.pullDownArticlesData);
            }
          })
          .catch((e) => {
            this.busy = false;
            this.showLoading = false;
            console.log(e);
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-con {
  display: flex;
  .carousel {
    width: 755px;

    .item {
      height: 29px;
      line-height: 29px;
      color: rgba(255, 255, 255, 100);
      font-size: 20px;
      font-family: SourceHanSansSC-regula;
      background: #8d1a11;
      width: auto;
      display: inline-flex;
      padding: 0 5px;
      position: absolute;
      left: 20px;
      bottom: 0px;
    }
  }
  .top {
    width: 255px;
    .box-item {
      margin-left: 10px;
      margin-bottom: 10px;
      height: calc(50% - 5px);
      position: relative;
      cursor: pointer;
      .item-title {
        position: absolute;
        bottom: 10px;
      }
    }
  }
}
.home-content {
  display: flex;
  padding-top: 10px;
  .con-item {
    // width: calc(25% - 5px);
    width: 245px;
    background: white;
    cursor: pointer;
    &:nth-child(4n + 1) {
      // background: blue;
      margin-right: 5px;
    }
    &:nth-child(4n + 2) {
      // background: pink;
      margin: 0 5px;
    }
    &:nth-child(4n + 3) {
      // background: yellow;
      margin: 0 5px;
    }
    &:nth-child(4n + 4) {
      // background: blue;
      margin-left: 5px;
    }
    .bg-img {
      position: relative;
      text-align: center;
      transition: transform 0.6s ease-in-out;
      position: relative;
      .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 50%;
        margin-left: -25px;
      }
    }
    .bg-img:hover {
      transform: scale(1.05);
    }
    .con-text {
      padding: 12px 10px 0px;
      .texts {
        overflow: hidden;
        height: 44px;
        font-weight: 400;
        color: #0f1419;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.tips-con {
  margin-top: 10px;
  display: flex;
  bottom: 0;
  left: 0;
  padding: 0 10px 10px;
  color: #666;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  .date {
    float: left;
  }
  .tips {
    float: right;
  }
}

.item-title {
  padding: 12px 10px 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -180deg,
    hsla(0, 0%, 74.9%, 0),
    rgba(0, 0, 0, 0.78) 97%
  );
  transition: all 0.5s;
}
#loadMore {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>